'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import s9 from 'style9';

const c = s9.create({
  header: {
    alignItems: 'baseline',
    color: 'rgb(var(--accent2))',
    columnGap: 'calc(var(--s) * 4)',
    fontSize: 'var(--fz-8)',
    justifyContent: 'flex-start',
    lineHeight: 'var(--lh-8)',

    gridColumnEnd: -1,
    gridColumnStart: 1,
    gridRowStart: 1,
    paddingInlineStart: 'calc(var(--s) * 3)',
    position: 'relative',
    zIndex: 1,

    '@media screen and (min-width: 37.5em)': {
      display: 'flex',
    },
  },
  nav: {
    display: 'flex',
    columnGap: 'calc(var(--s) * 3)',
    flexGrow: 1,

    '::after': {
      alignSelf: 'end',
      backgroundColor: 'currentColor',
      borderRadius: '4rem',
      content: '""',
      display: 'inline-block',
      flexGrow: 1,
      height: 'var(--s)',
      marginBottom: 'var(--s)',

      '@media screen and (min-width: 48em)': {
        marginInlineEnd: 'calc(var(--s) * 3)',
      },
    },
  },
  ul: {
    flexWrap: 'wrap',
    columnGap: 'calc(var(--s) * 4)',
    display: 'flex',
    fontSize: 'var(--fz-1)',
    justifyContent: 'flex-start',
    lineHeight: 'var(--lh-1)',
    transform: 'translateY(var(--s))',
    '@media screen and (min-width: 48em)': {
      fontSize: 'var(--fz-2)',
      lineHeight: 'var(--lh-2)',
    },
  },
  item: {
    fontWeight: 700,
    cursor: 'pointer',
    transition: 'all 0.25s ease-in-out',
    ':hover': {
      color: 'rgb(var(--accent1))',
      textDecoration: 'underline',
    },
    ':focus': {
      color: 'rgb(var(--accent1))',
      textDecoration: 'underline',
    },
  },
  separator: {
    '@media screen and (min-width: 26.25em)': {
      '::after': {
        aspectRatio: 0.7,
        backgroundColor: 'currentcolor',
        borderRadius: 'var(--s)',
        content: '""',
        display: 'inline-block',
        height: '1.4ex',
        width: '3px',
        marginInlineStart: 'calc(var(--s) * 4)',
      },
    },
  },
  current: {
    color: 'rgb(var(--accent1))',
    '::after': {
      color: 'rgb(var(--accent1))',
      content: '"*"',
      marginInlineStart: 'var(--s)',
      position: 'absolute',
      fontSize: 'var(--fz--1)',
      transform: 'translate(-0.2em,-0.3em)',
    },
    ':hover': {
      textDecoration: 'none',
    },
    ':focus': {
      textDecoration: 'none',
    },
  },
  hiddenOnMobile: {
    '@media screen and (max-width: 37.4375em)':
      // '@media screen and (max-width: 37.4375em), screen and (orientation: portrait) and (min-width: 48em)':
      {
        display: 'none',
      },
  },
});

const contactLink = (
  <>
    Contact <span className={s9(c.hiddenOnMobile)}>and Links</span>
  </>
);
const LINKS = [
  { name: 'Updates', href: '/' },
  { name: 'About', href: '/about' },
  { name: 'Media', href: '/media' },
  { name: contactLink, href: '/contact' },
];

export default function Nav() {
  const pathname = usePathname();

  return (
    <header className={s9(c.header)}>
      🅐
      <nav className={s9(c.nav)}>
        <ul className={s9(c.ul)}>
          {LINKS.map(({ name, href }, idx) => {
            const isCurrent = pathname === href;
            const hasSeparator = idx < LINKS.length - 1;
            return (
              <li key={href} className={hasSeparator ? s9(c.separator) : undefined}>
                <Link
                  aria-current={isCurrent ? 'page' : undefined}
                  className={s9(c.item, isCurrent && c.current)}
                  href={href}
                >
                  {name}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </header>
  );
}
